import { useCallback, useEffect, useState } from 'react';
// import { useNavigate } from 'react-router-dom';

import useVariableForm from 'hooks/useVariableForm';
import SectionWithBorder from 'components/SectionWithBorder';
import Title from 'components/Title';
import SaveButton from 'components/SaveButton';

// import useConfig from 'hooks/useConfig';
import useTranslation from './hooks/useTranslation';

import useGlobalStateHooks from 'hooks/useGlobalStateHooks';

import styles from './styles.module.scss';

import useData from './hooks/useData';
// import useDataClose from './hooks/useDataClose';
// import useStatus from 'pages/Activity/hooks/useStatus';
import ActivityFormComponent from 'components/ActivityFormComponent';
import { useFormCache } from 'hooks/use-form-cache.hook';

const AddActivityQuestions = props => {
    const {
        activityId,
        assignmentType,
        className = '',
        formId,
        formsRef,
        _groups,
        inactive = false,
        linkSchema,
        questions: presetQuestions,
        referencedUsers: presetReferencedUsers,
        refetchActivity,
        _users
    } = props;

    const {
        // NEXT,
        QUESTIONS
        // VIEW_FULL_FORM
    } = useTranslation();
    // const { HOME } = useConfig();

    const formCache = useFormCache({ activityId, formId });

    const [questions, setQuestions] = useState(() =>
        initQuestions(presetQuestions)
    );
    const [updatedQuestions, setUpdatedQuestions] = useState(() =>
        initUpdatedQuestions([])
    );
    const [_errorMessage, setErrorMessage] = useState();
    // const [activityStatus, setActivityStatus] = useStatus(linkSchema);
    const [createdFormSubmission, createFormSubmission, reset] =
        useData(linkSchema);
    // const [closedActivity, closeActivity, resetClose] = useDataClose(linkSchema);
    // const [closeAfterSubmit, setCloseAfterSubmit] = useState(false);
    const [referencedUsers, setReferencedUsers] = useState(
        presetReferencedUsers
    );
    const { useUserId } = useGlobalStateHooks();

    // const [_activities, setActivities] = useActivities();

    // const navigate = useNavigate();

    const [userId] = useUserId();

    const form = useVariableForm();

    const { handleForm } = form;
    const { handleError, handleSubmit, handleValid, handleValidPreBulk } = form;

    // const navigate = useNavigate();

    function saveQuestions(data = []) {
        const answers = data.reduce((acc, question) => {
            acc[question.id] = question.answer;
            return acc;
        }, {});
        formCache.saveQuestionsToStorage(answers);
        setQuestions(data);
    }

    function initQuestions(defaultQuestions) {
        const storageQuestions = formCache.loadQuestionsFromStorage();
        return defaultQuestions.map(question => {
            return {
                ...question,
                answer: storageQuestions?.[question.id] || question.answer
            };
        });
    }

    function initUpdatedQuestions(defaultQuestions) {
        const storageQuestions = formCache.loadQuestionsFromStorage();
        return storageQuestions
            ? Object.keys(storageQuestions).map(Number)
            : defaultQuestions;
    }

    const handleSave = useCallback(async () => {
        const output = handleForm();

        if (typeof output === 'string') {
            setErrorMessage(output);

            return;
        }
        const formObject = {};
        const newAnswers = [];
        for (const newAnswer of updatedQuestions) {
            for (const ques of questions) {
                if (ques?.id === newAnswer) {
                    if (ques?.answer) {
                        newAnswers.push({
                            answer: ques?.answer,
                            questionId: ques?.id
                        });
                    }
                }
            }
        }

        formObject['answers'] = newAnswers;
        formObject['activityId'] = activityId;
        formObject['formId'] = formId;
        formObject['userId'] = parseInt(userId);
        await createFormSubmission(formObject);
        await refetchActivity();
        return formObject;
        //eslint-disable-next-line
    }, [
        handleForm,
        setErrorMessage,
        createFormSubmission,
        activityId,
        formId,
        userId,
        questions
    ]);

    useEffect(() => {
        formsRef?.addFormRef(formId, {
            submit: handleSave
        })
    });

    // const nextUsers = referencedUsers.current && `assignee-${Number(assignmentType)+1}` in referencedUsers.current ? `assignee-${Number(assignmentType)+1}` :
    //     referencedUsers.current && `assignee-${Number(assignmentType)+2}` in referencedUsers.current ? `assignee-${Number(assignmentType)+2}` :
    //     referencedUsers.current && `assignee-${Number(assignmentType)+3}` in referencedUsers.current ? `assignee-${Number(assignmentType)+3}` :
    //     referencedUsers.current && `reviewer` in users ? `reviewer` : null;

    // const closeOrComplete = nextUsers ? 'Save and Complete' : 'Save and Close';
    // const ableToClose = nextUsers ? false : true;

    // const handleClose = useCallback(() => {
    //     let userToClose;
    //     let closedAssignmentType;
    //     setCloseAfterSubmit(true);
    //     const formObject = handleSave();

    //     const dataToSubmit = {
    //         ...formObject,
    //         id: activityId,
    //         status: 'complete'
    //     }

    //     loop1:
    //     for (let assignmentType in referencedUsers.current){
    //         const assignmentNumber = assignmentType.includes('assignee') ? Number(assignmentType.split('-')[1]): 0;
    //         const nextAssignmentType = referencedUsers.current && `assignee-${assignmentNumber+1}` in referencedUsers.current ? `assignee-${assignmentNumber+1}` :
    //                                     referencedUsers.current && `assignee-${assignmentNumber+2}` in referencedUsers.current ? `assignee-${assignmentNumber+2}` :
    //                                     referencedUsers.current && `assignee-${assignmentNumber+3}` in referencedUsers.current ? `assignee-${assignmentNumber+3}` :
    //                                     referencedUsers.current && `reviewer` in users ? `reviewer` : null;
    //         dataToSubmit.nextAssignmentType = nextAssignmentType;
    //         dataToSubmit.nextUsers = nextAssignmentType ? referencedUsers.current[nextAssignmentType].users : [];
    //         const {users: assignmentUsers, status} = referencedUsers.current[assignmentType];
    //         if (status === 'inprogress'){
    //             for (let user of assignmentUsers){
    //                 if (user.lastName === 'Group' && groups.current.includes(user.id)){
    //                     userToClose = user;
    //                     closedAssignmentType = assignmentType;
    //                     break loop1;
    //                 }else if (user.id === Number(userId)){
    //                     userToClose = user;
    //                     closedAssignmentType = assignmentType;
    //                     break loop1;
    //                 }
    //             }
    //         }
    //     }
    //     if (userToClose.lastName === 'Group'){
    //         dataToSubmit.groupId = userToClose.id;
    //     }else{
    //         dataToSubmit.userId = userToClose.id;
    //     }
    //     dataToSubmit.assignmentType = closedAssignmentType;
    //     dataToSubmit.userName = `${userToClose.firstName} ${userToClose.lastName}`;

    //     setActivityStatus(dataToSubmit);
    //     if(ableToClose){
    //         closeActivity({
    //             activityId,
    //             userId
    //         });
    //     }
    //     //eslint-disable-next-line
    // }, [activityId, handleSave, setActivityStatus, setCloseAfterSubmit]);

    useEffect(() => {
        if (!createdFormSubmission) {
            return;
        }

        //window.location.reload();

        // navigate(ACTIVITY_ROUTE.replace(':activityId', createdFormSubmission));

        reset();
    }, [/*navigate,*/ reset, createdFormSubmission]);

    useEffect(() => {
        const filteredQuestions = questions.filter(
            question => question?.type === 'checkBoxInput'
        );
        for (const question of filteredQuestions) {
            const answerArray = question?.answer
                ? question?.answer.split(',').map(x => parseInt(x))
                : [];
            const answerDict = answerArray.reduce(
                (a, v) => ({ ...a, [`${question?.id}_${v}`]: true }),
                {}
            );
            handleValidPreBulk(answerDict);
        }
        //eslint-disable-next-line
    }, [questions]);

    // useEffect(() => {
    //     if(activityStatus && !ableToClose){
    //         setActivities({});
    //         window.location.reload();
    //     }
    //     //eslint-disable-next-line
    // }, [activityStatus, setActivities]);

    // useEffect(() => {
    //     if (!closedActivity) {
    //         return;
    //     }

    //     navigate(HOME);

    //     resetClose();
    // }, [HOME, closedActivity, navigate, resetClose]);

    useEffect(() => {
        setReferencedUsers(presetReferencedUsers);
    }, [presetReferencedUsers]);

    return (
        <SectionWithBorder
            className={`${className} ${styles.addActivityQuestions}`}
        >
            <Title className={styles.title} text={QUESTIONS} />

            {questions.map(
                ({
                    answer,
                    choices,
                    formComponent,
                    id,
                    index,
                    questionId,
                    name,
                    type
                }) => {
                    if (formComponent === 'newForm') {
                        return (
                            <ActivityFormComponent
                                answer={answer}
                                choices={choices}
                                formComponent={formComponent}
                                handleError={handleError}
                                handleForm={handleForm}
                                handleSubmit={handleSubmit}
                                handleValid={handleValid}
                                id={id}
                                inactive={inactive}
                                index={index}
                                name={name}
                                questionId={questionId}
                                questions={questions}
                                setQuestions={saveQuestions}
                                setUpdatedQuestions={setUpdatedQuestions}
                                type={type}
                                updatedQuestions={updatedQuestions}
                            />
                        );
                    } else {
                        return <></>;
                    }
                }
            )}

            {/* <FullFormDrawer
                handleError={handleError}
                handleSave={handleSave}
                handleSaveClose={handleClose}
                handleSubmit={handleSubmit}
                handleValid={handleValid}
                questions={questions}
            >
                {({ toggle }) => (
                    <InfoHollowButton
                        className={styles.infoHollowButton}
                        onClick={toggle}
                        text={VIEW_FULL_FORM}
                    />
                )}
            </FullFormDrawer> */}

            {!inactive &&
                referencedUsers.current &&
                referencedUsers.current[`assignee-${assignmentType}`]
                    ?.status === 'inprogress' && (
                    <SaveButton
                        className={styles.saveButton}
                        onClick={handleSave}
                        saveText={'Save'}
                    />
                )}

            {/* {!inactive && referencedUsers.current && referencedUsers.current[`assignee-${assignmentType}`]?.status === 'inprogress' &&
            <SaveButton
                className={styles.saveAndCloseButton}
                onClick={handleClose}
                saveText={closeOrComplete}
            />} */}
        </SectionWithBorder>
    );
};

export default AddActivityQuestions;
