import titleCase from "utilities/titleCase";
import formatDate from 'utilities/formatDate';

const getReferenceNamesFromActivityPolicies = activityPolicies => {
    if (activityPolicies.length === 0) return [];

    // Code defensively, since this code existed before an update to use 
    //   document_section_id. Not sure if still in use.
    //
    if (
        !Object.keys(activityPolicies[0] ?? {}).includes('document_section_id')
    ) {
        return activityPolicies.reduce(
            (accumulator, { document: policy, section }) => {
                if (section == null) {
                    accumulator.push({
                        description: policy?.description,
                        id: policy?.id,
                        name: policy?.name,
                        riskLevel: policy?.risk_label
                    });
                } else {
                    accumulator.push({
                        description: section?.description,
                        id: policy?.id,
                        name: section?.name,
                        riskLevel: section?.riskLevel
                    });
                }

                return accumulator;
            },
            []
        );
    }

    const referenceNames = [];
    for (const activityPolicy of activityPolicies) {
        if (activityPolicy?.document_section_id) {
            const policySection =
                activityPolicy?.document?.policy_sections?.find(
                    policySection =>
                        policySection?.section?.id ===
                        activityPolicy.document_section_id
                );
            const referenceName = policySection?.section?.name;
            if (referenceName) {
                referenceNames.push(referenceName);
            }
        } else {
            const referenceName = activityPolicy?.document?.name;
            if (referenceName) {
                referenceNames.push(referenceName);
            }
        }
    }

    return referenceNames;
};

const parseDetailedDataForReport = async (data, isAll, tenants, querySchemas, schema, userId) => {
    const schemaData = ({ data, isAll, querySchemas, schema, user }) => (data ?? []).reduce(
        (accumulator, activity) => {
            const {
                activity_attachments: activityAttachments = [],
                activity_entities: activityEntities = [],
                activity_forms: activityForms = [],
                activity_histories: activityHistory = [],
                activity_recurrences: activityRecurrences = [],
                activity_type: activityType,
                activity_linkages: activityLinkagesOne = [],
                activity_policies: activityPolicies = [],
                activityLinkagesByLinkActivityId: activityLinkagesTwo = [],
                cases,
                closed,
                comments = [],
                closed_date: activityClosedDate,
                dropdown_selections: dropdownSelections,
                due_date: activityDueDate,
                id: activityId,
                instructions,
                is_hierarchical: isHierarchical,
                log_activities: logs,
                name: activityName,
                start_date: activityStartDate,
                user_activities: userActivities = [],
                group_completion_rate: groupCompletionRate
            } = activity;

            if (userActivities.length > 0) {
                const groups = userActivities.reduce(
                    (accumulator, user) => {
                        const {
                            assignment_type: group,
                            status,
                            tenant_user: userDetails,
                            group: userGroup
                        } = user

                        if(userDetails || userGroup){
                            const { id } = (userDetails) ? userDetails : userGroup;
                            if(querySchemas.length > 0 && querySchemas[0] !== schema){
                                for(const tenant of querySchemas){
                                    const userArray = (userDetails) && (`users_${tenant.value}` in userDetails) && userDetails[`users_${tenant.value}`].length > 0
                                    ? userDetails[`users_${tenant.value}`][0] : (userGroup)
                                    ? {'first_name': userGroup.name, 'last_name': 'Group', 'profile_photo': ''} :
                                    {'first_name': '', 'last_name': '', 'profile_photo': ''}
                                    const {
                                        first_name: firstName,
                                        last_name: lastName,
                                        profile_photo: profilePhoto
                                    } = userArray;

                                    const values = {
                                        firstName,
                                        id,
                                        lastName,
                                        profilePhoto,
                                        status,
                                    }
                                    if(values.firstName !== ''){
                                        if (typeof accumulator === 'object' && group in accumulator){
                                            accumulator[group].users.push(values);
                                        }else if(typeof accumulator === 'object'){
                                            accumulator[group] = {users: [values]};
                                        }else{
                                            accumulator = {};
                                            accumulator[group] = {users: [values]};
                                        }
                                    }
                                }
                            }else{
                                const userArray = (userDetails) && (`users_${schema}` in userDetails) && userDetails[`users_${schema}`].length > 0
                                ? userDetails[`users_${schema}`][0] : (userGroup)
                                ? {'first_name': userGroup.name, 'last_name': 'Group', 'profile_photo': ''} :
                                {'first_name': '', 'last_name': '', 'profile_photo': ''}

                                const {
                                    first_name: firstName,
                                    last_name: lastName,
                                    profile_photo: profilePhoto
                                } = userArray;

                                const values = {
                                    firstName,
                                    id,
                                    lastName,
                                    profilePhoto,
                                    status,
                                }
                                if (typeof accumulator === 'object' && group in accumulator){
                                    accumulator[group].users.push(values);
                                }else if(typeof accumulator === 'object'){
                                    accumulator[group] = {users: [values]};
                                }else{
                                    accumulator = {};
                                    accumulator[group] = {users: [values]};
                                }
                            }
                        }
                        return accumulator
                    },{}
                );
                const userGroups = Object.keys(groups)
                                .sort()
                                .reduce(function (acc, key) {
                                    acc[key] = groups[key];
                                    return acc;
                                }, {})
                for(const [index, [key]] of Object.entries(Object.entries(userGroups))){
                    const group = userGroups[key].users;
                    var groupProgress
                    if(groupCompletionRate && groupCompletionRate[index]){
                        if (typeof groupCompletionRate[index] === 'string' && groupCompletionRate[index] === 'all'){
                            groupProgress = group.some(
                                ({ status }) => status !== 'complete'
                            );
                        }else if (typeof groupCompletionRate[index] === 'number' && groupCompletionRate[index] > 1){
                            const complete = group.filter(({status}) => status === 'complete');
                            groupProgress = ((complete.length/group.length)*100) >= groupCompletionRate['index']
                        }else{
                            groupProgress = group.every(
                                ({ status }) => status !== 'complete'
                            );
                        }
                        userGroups[key]['status'] = ((groupProgress) ? 'inprogress' : 'complete')
                    }
                }

                const users = userActivities.reduce(
                    (accumulator, { assignment_type: assignmentType, status, tenant_user: tenantUser, group }) => {
                        const groupStatus = userGroups[assignmentType]?.status === 'complete' ? userGroups[assignmentType]?.status : status;
                        if( tenantUser ){
                            const id = tenantUser.id;

                            var users;
                            if(tenants.length > 1){
                                for(const tenant of tenants){
                                    if(tenantUser[`users_${tenant.value}`] && tenantUser[`users_${tenant.value}`].length > 0){
                                        users = tenantUser[`users_${tenant.value}`];
                                    }
                                }
                            }else{
                                users = tenantUser[`users_${schema?.value}`];
                            }

                            const {
                                first_name: firstName,
                                last_name: lastName,
                                profile_photo: profilePhoto
                            } = users && users.length > 0 ? users[0] : {'first_name': '', 'last_name': '', 'profile_photo': ''};

                            const assignmentNumber = assignmentType === 'reviewer' ? 0 : Number(assignmentType.split('-')[1])

                            if(assignmentType !== 'reviewer'){
                                accumulator.assigned.push({
                                    assignmentNumber,
                                    firstName,
                                    groupStatus,
                                    id,
                                    lastName,
                                    profilePhoto,
                                    status
                                });
                            }else{
                                accumulator.reviewer.push({
                                    assignmentNumber,
                                    firstName,
                                    groupStatus,
                                    id,
                                    lastName,
                                    profilePhoto,
                                    status
                                });
                            }

                            return accumulator;
                        }else if (group){
                            const id = group.id;

                            const {
                                name: firstName
                            } = group;

                            const assignmentNumber = assignmentType === 'reviewer' ? 0 : Number(assignmentType.split('-')[1])

                            if(assignmentType !== 'reviewer'){
                                accumulator.assigned.push({
                                    assignmentNumber,
                                    firstName,
                                    groupStatus,
                                    id,
                                    lastName: "Group",
                                    profilePhoto: '',
                                    status: ''
                                });
                            }else{
                                accumulator.reviewer.push({
                                    assignmentNumber,
                                    firstName,
                                    groupStatus,
                                    id,
                                    lastName: "Group",
                                    profilePhoto: '',
                                    status: ''
                                });
                            }

                            return accumulator;
                        }else{
                            return accumulator
                        }
                    },
                    {assigned: [], reviewer: []}
                );

                const recurrence =  activityRecurrences ? activityRecurrences.reduce(
                    (accumulator, activityRecurrence) => {
                        accumulator.push(activityRecurrence?.activity_recurrence?.recurrence_type);
                        return accumulator
                    },
                    []
                ) : [];

                const startDate = new Date(activityStartDate);
                const dueDate = new Date(activityDueDate);
                const closedDate = new Date(activityClosedDate);
                const userInt = parseInt(user);
                const entities = activityEntities ? activityEntities.reduce(
                    (accumulator, activityEntity) => {
                        const {entity} = activityEntity;
                        const {name} = entity;
                        accumulator.push(name);
                        return accumulator
                    },[]
                ) : [];

                const usersSortOrder = ['inprogress', 'complete']
                const individualStatusSortedAssigned = [...users.assigned].sort(function(a, b) {
                    return usersSortOrder.indexOf(a.status) - usersSortOrder.indexOf(b.status);
                });
                const assignmentSortedAssigned = [...individualStatusSortedAssigned].sort(function(a, b) {
                    return a.assignmentNumber - b.assignmentNumber;
                });
                const sortedAssigned = [...assignmentSortedAssigned].sort(function(a, b) {
                    return usersSortOrder.indexOf(a.groupStatus) - usersSortOrder.indexOf(b.groupStatus);
                });

                const newActivity = {
                    activityAttachments,
                    activityForms,
                    activityHistory,
                    activityLinkages: [...activityLinkagesOne, ...activityLinkagesTwo],
                    activityPolicies,
                    assigned: sortedAssigned,
                    cases,
                    closed,
                    closedDate: formatDate(closedDate),
                    comments,
                    dropdownSelections,
                    dropdowns: activityType?.dropdown,
                    dueDate: formatDate(dueDate),
                    entities,
                    id: activityId,
                    instructions,
                    isHierarchical,
                    logs,
                    recurrence: recurrence[0],
                    reviewer: users.reviewer.sort(function (x, y) {
                        return x.id === userInt ? -1 : y.id === userInt ? 1 : 0;
                    }),
                    schema,
                    sortDate: dueDate,
                    startDate: formatDate(startDate),
                    text: activityName,
                    type: activityType?.name
                };

                if (
                    isAll ||
                    (!newActivity.isHierarchical && newActivity.assigned.some(({ id }) => id === userInt) && newActivity.assigned.filter(x => x.id === userInt)[0]?.groupStatus !== 'complete' && newActivity.assigned.filter(x => x.id === userInt)[0]?.status !== 'complete') ||
                    (newActivity.isHierarchical && newActivity.assigned.some(({ id }) => id === userInt) && (newActivity.assigned.filter(x => x.id === userInt)[0].assignmentNumber === 1 || newActivity.assigned.filter(y => y.assignmentNumber < newActivity.assigned.filter(x => x.id === userInt)[0].assignmentNumber).every(x => x.groupStatus === 'complete')) && newActivity.assigned.filter(x => x.id === userInt)[0]?.groupStatus !== 'complete' && newActivity.assigned.filter(x => x.id === userInt)[0]?.status !== 'complete') ||
                    (newActivity.assigned.every(x => x.groupStatus === 'complete') && newActivity.reviewer.some(({ id }) => id === userInt))
                ) {
                    if (closed) {
                        accumulator.completed.push(newActivity);
                    } else if (dueDate.setHours(23) < new Date()) {
                        accumulator.overdue.push(newActivity);
                    } else if (startDate <= new Date()){
                        accumulator.inProgress.push(newActivity);
                    } else {
                        accumulator.upcoming.push(newActivity);
                    }
                }
            }else{
                if(isAll){
                    const newActivity = {
                        activityAttachments: [],
                        activityForms: [],
                        activityHistory: [],
                        activityLinkages: [],
                        activityPolicies: [],
                        assigned: [],
                        cases: [],
                        closed,
                        closedDate: formatDate(new Date(activityClosedDate)),
                        comments: [],
                        dropdownSelections: null,
                        dropdowns: activityType?.dropdown,
                        dueDate: formatDate(new Date(activityDueDate)),
                        entities: activityEntities.reduce(
                            (accumulator, activityEntity) => {
                                const {entity} = activityEntity;
                                const {name} = entity;
                                accumulator.push(name);
                                return accumulator
                            },[]),
                        id: activityId,
                        instructions,
                        isHierarchical,
                        logs: [],
                        recurrence: activityRecurrences.reduce(
                            (accumulator, activityRecurrence) => {
                                accumulator.push(activityRecurrence?.activity_recurrence?.recurrence_type);
                                return accumulator
                            },
                            []
                        )[0],
                        reviewer: [],
                        schema,
                        sortDate: new Date(activityDueDate),
                        startDate: formatDate(new Date(activityStartDate)),
                        text: activityName,
                        type: activityType.name
                    };
                    if(closed){
                        accumulator.completed.push(newActivity);
                    } else if (new Date(activityDueDate).setHours(23) < new Date()) {
                        accumulator.overdue.push(newActivity);
                    } else if (new Date(activityStartDate) <= new Date()){
                        accumulator.inProgress.push(newActivity);
                    } else {
                        accumulator.upcoming.push(newActivity);
                    }
                }
            }

            accumulator.completed = accumulator.completed.sort(
                (objA, objB) => {
                    if(objA.sortDate > objB.sortDate) return 1;
                    if(objA.sortDate < objB.sortDate) return -1;
                    if(objA.type > objB.type) return 1;
                    if(objA.type < objB.type) return -1;
                    if(objA.text > objB.text) return 1;
                    if(objA.text < objB.text) return -1;
                    return 0;
                }
            );

            accumulator.overdue = accumulator.overdue.sort(
                (objA, objB) => {
                    if(objA.sortDate > objB.sortDate) return 1;
                    if(objA.sortDate < objB.sortDate) return -1;
                    if(objA.type > objB.type) return 1;
                    if(objA.type < objB.type) return -1;
                    if(objA.text > objB.text) return 1;
                    if(objA.text < objB.text) return -1;
                    return 0;
                }
            );

            accumulator.inProgress = accumulator.inProgress.sort(
                (objA, objB) => {
                    if(objA.sortDate > objB.sortDate) return 1;
                    if(objA.sortDate < objB.sortDate) return -1;
                    if(objA.type > objB.type) return 1;
                    if(objA.type < objB.type) return -1;
                    if(objA.text > objB.text) return 1;
                    if(objA.text < objB.text) return -1;
                    return 0;
                }
            );

            accumulator.upcoming = accumulator.upcoming.sort(
                (objA, objB) => {
                    if(objA.sortDate > objB.sortDate) return 1;
                    if(objA.sortDate < objB.sortDate) return -1;
                    if(objA.type > objB.type) return 1;
                    if(objA.type < objB.type) return -1;
                    if(objA.text > objB.text) return 1;
                    if(objA.text < objB.text) return -1;
                    return 0;
                }
            );

            return accumulator;
        },
        {
            completed: [],
            inProgress: [],
            overdue: [],
            upcoming: []
        }
    );

    const getTenantResults = async () => {
        if (Array.isArray(data)) {
            return await schemaData({ data, isAll, querySchemas, schema, user: userId });
        } 

        if (Array.isArray(tenants) && tenants.length > 0) {
            const tenantDataPromises = tenants.map(async (t) => {
                if (t) {
                    return await schemaData({ data: data[`${t?.value}_activity`], isAll, querySchemas, schema: t, user: userId });
                }
                return null;
            });
    
            const tenantData = (await Promise.all(tenantDataPromises)).filter(t => t !== null);
    
            const results = { completed: [], inProgress: [], overdue: [], upcoming: [] };
            
            tenantData.forEach(d => {
                if (d) {
                    results.completed.push(...d?.completed);
                    results.inProgress.push(...d?.inProgress);
                    results.overdue.push(...d?.overdue);
                    results.upcoming.push(...d?.upcoming);
                }
            });
    
            return results;
        } else {
            return await schemaData({ data, isAll, querySchemas, schema, user: userId });
        }
    }

    const {
        completed: complete,
        inProgress: inprogress,
        overdue,
        upcoming
    } = await getTenantResults();

    const headers = ['Record Category', 'Activity Name', 'Status', 'Start Date', 'Due Date', 'Close Date', 'Activity Type', 'Entities', 'Recurrence', 'Assigned', 'Reviewer', 'Activity Instructions', 'Activity Dropdown Selection', 'Linkage', 'Reference', 'Form, Question and Answers', 'Attachments', 'Comments' ];
    const rows = [];
    function addRow(data, status){
        const row = [];
        row.push('Activity')
        row.push(data?.text ? data?.text : "");
        row.push(status);
        row.push(data?.startDate ? data?.startDate : "");
        row.push(data?.dueDate ? data?.dueDate : "");
        row.push(data?.closedDate && data?.closedDate !== 'Dec 31, 1969' ? data?.closedDate : "");
        row.push(data?.type ? data?.type : "");
        row.push(data?.entities && Array.isArray(data?.entities) && data?.entities.length > 0 ? data?.entities.join(', ') : "");
        row.push(data?.recurrence ? data?.recurrence : "");

        const assignedUsers = data?.assigned.map(user => {
            return `${user.firstName} ${user.lastName}${user.status ? ` - ${titleCase(user.status)}` : ''}`;
        }).join('\n');
        row.push(assignedUsers);

        const reviewers = data?.reviewer.map(user => {
            return `${user.firstName} ${user.lastName}`;
        }).join('\n');
        row.push(reviewers);

        const concatenatedInstructions = data?.instructions ? data.instructions.replace(/(<([^>]+)>)/ig, '') : '';
        row.push(concatenatedInstructions);

        const dropdowns = data?.dropdowns ? data?.dropdowns.map(dropdown => {
            let selection = '';
            if(data?.dropdownSelections && dropdown?.id in data?.dropdownSelections){
                selection = dropdown?.options[data?.dropdownSelections[dropdown?.id]];
            }
            return `${dropdown?.title}: ${selection}`;
        }).join('\n') : '';
        row.push(dropdowns);

        const concatenatedLinkages = data?.activityLinkages.map(linkage => {
            return `${linkage?.activity?.name}, Due: ${formatDate(new Date(linkage?.activity?.due_date))}, Start: ${formatDate(new Date(linkage?.activity?.start_date))}, Closed: ${formatDate(new Date(linkage?.activity?.closed_date))}, Instructions: ${linkage?.activity?.instructions.replace(/(<([^>]+)>)/ig, '')}`;
        }).join('\n');
        row.push(concatenatedLinkages);

        const referenceNames =
            data?.activityPolicies.length > 0
                ? getReferenceNamesFromActivityPolicies(data.activityPolicies)
                : null;
        row.push(
            referenceNames && referenceNames.length > 0
                ? referenceNames.join('\n')
                : ''
        );

        const forms = [];
        data?.activityForms.forEach(form => {
            let formDetails = `${form?.assignment}`;
            form?.form?.form_questions.forEach(question => {
                formDetails += `\nQuestion: ${question?.question?.name}`;
                const formAnswers = (question?.activity_form_answers ?? []).filter(answer => answer?.activity_id === data?.id);
                if (formAnswers.length > 0) {
                    const lastIndex = formAnswers.length - 1;
                    formDetails += ' - Answer: ' + JSON.stringify(formAnswers[lastIndex]?.answer ?? '');
                }
            });
            forms.push(formDetails);
        });
        row.push(forms.join('\n'));

        const concatenatedAttachments = data?.activityAttachments.map(attachment => {
            return `${attachment?.name}`;
        }).join('\n');
        row.push(concatenatedAttachments);

        const comments = [];
        for (const comment of data?.comments ?? []) {
            try {
                const date = formatDate(new Date(comment?.created_at));
                const text = comment?.text.replace(/(<([^>]+)>)/gi, '');
                const name = [
                    comment?.user_comments[0]?.tenant_user?.[`user_${schema}`]?.first_name || '',
                    comment?.user_comments[0]?.tenant_user?.[`user_${schema}`]?.last_name || ''
                ]
                    .filter(Boolean)
                    .join(' ');
                comments.push(`${date} - ${name}\n${text}`);
            } catch (error) {
            }
        }
        row.push(comments.join('\n'));
        
        rows.push(row);
    }

    for(const o of overdue){
        addRow(o, 'Overdue');
    }
    for(const i of inprogress){
        addRow(i, 'In Progress');
    }
    for(const u of upcoming){
        addRow(u, 'Upcoming');
    }
    for(const c of complete){
        addRow(c, 'Complete');
    }
    const morphedData = {headers, rows}
    return morphedData
}

export default parseDetailedDataForReport