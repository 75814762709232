import * as ExcelJS from 'exceljs';

// Border styles
//
const borderHeader = {
    bottom: { color: { argb: '219653' }, style: 'thin' },
    left: { color: { argb: '219653' }, style: 'thin' },
    right: { color: { argb: '219653' }, style: 'thin' },
    top: { color: { argb: '219653' }, style: 'thin' }
};
const standardBorder = {
    bottom: { color: { argb: 'eeeeee' }, style: 'thin' },
    left: { color: { argb: 'eeeeee' }, style: 'thin' },
    right: { color: { argb: 'eeeeee' }, style: 'thin' },
    top: { color: { argb: 'eeeeee' }, style: 'thin' }
};

// Fill styles
//
const fillHeader = {
    fgColor: { argb: '219653' },
    pattern: 'solid',
    type: 'pattern'
};
const fillStandard = {
    fgColor: { argb: 'FFFFFF' },
    pattern: 'solid',
    type: 'pattern'
};
const fillStatusComplete = {
    fgColor: { argb: 'def2e1' },
    pattern: 'solid',
    type: 'pattern'
};
const fillStatusDraft = {
    fgColor: { argb: 'f2f0fd' },
    pattern: 'solid',
    type: 'pattern'
};
const fillStatusInProgress = {
    fgColor: { argb: 'd6e0f9' },
    pattern: 'solid',
    type: 'pattern'
};
const fillStatusOverdue = {
    fgColor: { argb: 'f6b8b9' },
    pattern: 'solid',
    type: 'pattern'
};

// Font styles
//
const fontHyperlink = { color: { argb: '0000FF' }, underline: true };
const fontWhite = { color: { argb: 'FFFFFFFF' } };

// Cell styles
//
const cellMaxHeight = 120;
const cellMinWidth = 30;
const cellLargeWidth = 50;

const generateDetailedXLSX = async sheets => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Skematic';
    workbook.lastModifiedBy = 'Skematic';
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    workbook.properties.date1904 = true;
    workbook.calcProperties.fullCalcOnLoad = true;

    // Format any cells with date strings to be actual dates, for Excel formatting
    //
    for (let i = 0; i < sheets.length; i++) {
        for (let j = 0; j < sheets[i].rows.length; j++) {
            for (let k = 0; k < sheets[i].rows[j].length; k++) {
                if (
                    typeof sheets[i].rows[j][k] !== 'number' &&
                    !isNaN(Date.parse(sheets[i].rows[j][k]))
                ) {
                    console.log(
                        'COREYY',
                        'changing to date',
                        sheets[i].rows[j][k]
                    );
                    sheets[i].rows[j][k] = new Date(sheets[i].rows[j][k]);
                }
            }
        }
    }

    for (const { headers, name, rows } of sheets) {
        const sheet = workbook.addWorksheet(name, {
            views: [{ state: 'frozen', ySplit: 1 }]
        });
        const headerRow = sheet.addRow(headers);
        headerRow.border = borderHeader;
        headerRow.fill = fillHeader;
        headerRow.font = fontWhite;

        const statusIndex = headers.indexOf('Status');
        for (const row of rows) {
            const caseRow = sheet.addRow(row);
            caseRow.border = standardBorder;
            const status = row[statusIndex];
            caseRow.fill = ['Approved', 'Complete'].includes(status)
                ? fillStatusComplete
                : ['Withdrawn', 'In Progress'].includes(status)
                ? fillStatusInProgress
                : ['Draft'].includes(status)
                ? fillStatusDraft
                : ['Overdue'].includes(status)
                ? fillStatusOverdue
                : fillStandard;
        }

        // Set column widths
        //
        const largeWidthIndexes = [
            headers.indexOf('Attachments'),
            headers.indexOf('Comments')
        ];
        sheet.columns.forEach(column => {
            let maxContentWidth = 0;

            column.eachCell({ includeEmpty: true }, cell => {
                if (cell.value?.toString().includes('\n')) {
                    cell.alignment = { wrapText: true };
                }
                if (cell.value?.hyperlink) {
                    cell.font = fontHyperlink;
                }
                maxContentWidth = Math.max(
                    maxContentWidth,
                    cell.value?.toString().length ?? 10
                );
            });

            if (largeWidthIndexes.includes(column.number - 1)) {
                column.width = cellLargeWidth;
            } else {
                column.width = Math.min(maxContentWidth, cellMinWidth);
            }
        });

        // Set row heights
        //
        sheet.eachRow({ includeEmpty: true }, row => {
            let maxHeightFound = 15; // Start with a base height for single line

            row.eachCell({ includeEmpty: true }, cell => {
                const cellValue = cell.value;
                if (typeof cellValue === 'string') {
                    const lines = cellValue.split('\n').length;
                    maxHeightFound = Math.max(maxHeightFound, (lines - 1) * 15);
                }
            });

            if (maxHeightFound > cellMaxHeight) {
                row.height = cellMaxHeight;
            }
        });
    }

    const buffer = await workbook.xlsx.writeBuffer();
    return buffer;
};

export default generateDetailedXLSX;
